import React, { ReactNode } from 'react';

import { Alert as MuiAlert } from '@mui/material';
import cx from 'classnames';
import Icon from 'src/components/icon/icon';
import { SpacingProps, useSpacing } from 'src/hooks/spacing';
import { PropsFrom } from 'src/types';

import * as styles from './alert.module.less';

interface Props extends SpacingProps {
  title?: ReactNode;
  variant?: 'error' | 'success' | 'neutral' | 'warning' | 'stripe';
  icon?: PropsFrom<typeof Icon>['name'];
}

const Alert: React.FC<Props> = ({ spacing, title, children, variant = 'neutral', icon = 'md-alert' }) => {
  const classes = useSpacing(spacing);
  return (
    <MuiAlert
      icon={<Icon name={icon} />}
      className={cx(classes, styles.alert, styles[variant])}
      classes={{ icon: styles.icon }}
    >
      {title && <div className={styles.title}>{title}</div>}
      {children}
    </MuiAlert>
  );
};

export default Alert;
