import gql from 'graphql-tag';

export const GET_TOURNAMENT_GROUP_DETAIL = gql`
  query GetTournamentPool($poolId: UUID!) {
    tournamentPool(id: $poolId) {
      id
      name
      fromDate
      toDate
      levelId
      category
      maxTournamentsPerPlayer
      maxDivisionsPerPlayerPerTournament
      priorityPlayerSelection
      websitePromotion
      countDivisionsAsTournaments
      registrationDates {
        entriesCloseDateTime
        entriesOpenDateTime
        timeZone
      }
      tournaments {
        id
        name
        level {
          name
        }
        timings {
          startDate
          endDate
        }
        primaryLocation {
          town
          county
        }
        director {
          firstName
          lastName
        }
        registrationRestrictions {
          entriesCloseDate
          entriesCloseDateTime
          entriesOpenDate
          entriesOpenDateTime
          timeZone
        }
      }
    }
  }
`;
export const GET_ADD_GROUP_TOURNAMENT_OPTIONS = gql`
  query GroupTournamentOptions(
    $limit: Int!
    $offset: Int!
    $filters: [PropertyFilterInput!]!
    $orgId: UUID!
    $sorts: [PropertySortInput!]
  ) {
    paginatedTournaments(
      tournamentFilter: { sanctioningOrganisationId: $orgId }
      paginationOptions: { offset: $offset, limit: $limit, filters: $filters, sorts: $sorts }
    ) {
      totalItems
      items {
        id
        name
        timings {
          startDate
          endDate
          drawsPublishedDate
          drawsPublishedTime
          selectionsPublishedDate
          selectionsPublishedTime
          endTime
          startTime
        }
        tournamentPool {
          id
        }
        primaryLocation {
          postcode
          county
          name
          town
        }
        registrationRestrictions {
          entriesCloseDate
          entriesCloseDateTime
          entriesOpenDate
          entriesOpenDateTime
          timeZone
        }
      }
    }
  }
`;

export const ADD_TOURNAMENTS_TO_POOL = gql`
  mutation AddTournamentsToPool($tournamentIds: [UUID!]!, $poolId: UUID!) {
    addTournamentsToPool(tournamentPoolId: $poolId, tournamentIds: $tournamentIds) {
      id
    }
  }
`;

export const REMOVE_TOURNAMENTS_FROM_POOL = gql`
  mutation RemoveTournamentsFromPool($tournamentPoolId: UUID!, $tournamentIds: [UUID!]!) {
    removeTournamentsFromPool(tournamentPoolId: $tournamentPoolId, tournamentIds: $tournamentIds) {
      id
    }
  }
`;
