import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';
import TournamentGroup from 'src/components/tournament-group/tournament-group';

const TournamentGroupPage = () => {
  return (
    <Router basepath="/tournaments/groups">
      <TournamentGroupRoute path="/:groupId" />
    </Router>
  );
};

interface TournamentGroupProps extends RouteComponentProps {
  groupId?: string;
}

const TournamentGroupRoute: React.FC<TournamentGroupProps> = ({ groupId }) => {
  return (
    <Layout>
      <SEO title="Tournament Group" />
      <PrivateRoute>
        <TournamentGroup groupId={groupId} />
      </PrivateRoute>
    </Layout>
  );
};

export default TournamentGroupPage;
